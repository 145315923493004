import React from 'react';

function CheckedCircle(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <mask
        id='mask0_1871_2548'
        style={{ maskType: 'alpha' }}
        width='24'
        height='24'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
      </mask>
      <g mask='url(#mask0_1871_2548)'>
        <path
          fill={props.fill ?? '#333'}
          d='M10.58 16.254l6.724-6.723-1.054-1.054-5.67 5.67-2.85-2.85-1.053 1.053 3.904 3.904zm1.422 5.246a9.255 9.255 0 01-3.705-.748 9.598 9.598 0 01-3.018-2.03 9.591 9.591 0 01-2.03-3.016 9.245 9.245 0 01-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 012.03-3.018 9.592 9.592 0 013.016-2.03 9.245 9.245 0 013.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 013.018 2.03 9.592 9.592 0 012.03 3.016 9.245 9.245 0 01.749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 01-2.03 3.018 9.592 9.592 0 01-3.016 2.03 9.245 9.245 0 01-3.704.749z'
        ></path>
      </g>
    </svg>
  );
}

CheckedCircle.displayName = 'CheckedCircle';

export default CheckedCircle;
