import { Link } from 'gatsby';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { executeGA } from '../../../redux/actions';
import { ChallengeWithCompletedNode } from '../../../redux/prop-types';

import CheckedCurriculum from '../../../assets/icons/checked-curriculum';
import LockChallenge from '../../../assets/icons/lock-challenge';
import UncheckedCurriculum from '../../../assets/icons/unchecked-curriculum';
import News from '../../../assets/icons/news';

import Terminal from '../../../assets/icons/terminal';
import { AccessLevel } from '../../../utils/enums/access-levels';

import './challenges.css';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ executeGA }, dispatch);

interface Challenges {
  readonly challengesWithCompleted: ChallengeWithCompletedNode[];
  readonly access: string;
}

function Challenges({
  challengesWithCompleted,
  access
}: Challenges): JSX.Element {
  return (
    <ul className={`map-challenges-ul`}>
      {/* challenges */}
      {challengesWithCompleted.map((challenge, index) => (
        /* challenge */
        <li
          className={`challenger ${verifyChallengeEnableContainer(
            challengesWithCompleted,
            index,
            access
          )}`}
          id={challenge.dashedName}
          key={'map-challenge' + challenge.fields.slug}
        >
          <Link
            className={verifyChallengeEnable(
              challengesWithCompleted,
              index,
              access
            )}
            to={challenge.fields.slug}
          >
            <div className='challenge-link-items'>
              <div className='challenge-link-title'>
                {renderIconAndTexto(challenge.title)}
              </div>
            </div>
            {isCompleteChallenge(challengesWithCompleted, index, access)}
          </Link>
        </li>
      ))}
    </ul>
  );
}

function renderIconAndTexto(title: string): JSX.Element {
  let icon = null;
  let text = title;

  if (title.includes('📄')) {
    icon = <Terminal />;
    text = title.replace('📄 ', '');
    // these are different icon
  } else if (title.includes('▶️') || title.includes('▶')) {
    icon = <News />;
    text = title.replace('▶️ ', '');
    text = text.replace('▶ ', '');
  } else if (title.includes('📘')) {
    icon = <News />;
    text = title.replace('📘 ', '');
  }

  return (
    <>
      {icon}
      <p>{text}</p>
    </>
  );
}

function verifyChallengeEnableContainer(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  access: string
) {
  switch (access) {
    case AccessLevel.COMPLETE:
      return verifyAccessComplete(challenges, index);
    case AccessLevel.LIMITED:
      return verifyAccessLimited(challenges, index);
    case AccessLevel.BLOCKED:
      return verifyAccessBlocked(challenges, index);
    default:
      return verifyAccessComplete(challenges, index);
  }
}

function verifyAccessLimited(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (index === 0) {
    if (challenges[index].isCompleted) {
      return '';
    } else if (challenges[index].order > 5) {
      return 'challenger-disabled';
    } else {
      return '';
    }
  }
  if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return '';
    } else if (challenges[index].order > 5) {
      return 'challenger-disabled';
    } else {
      return '';
    }
  } else {
    return 'challenger-disabled';
  }
}

function verifyAccessBlocked(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (challenges[index].isCompleted) {
    return '';
  } else {
    return 'challenger-disabled';
  }
}

function verifyAccessComplete(
  challenges: ChallengeWithCompletedNode[],
  index: number
) {
  if (index === 0) {
    return '';
  }
  if (challenges[index - 1].isCompleted) {
    return '';
  } else {
    return 'challenger-disabled';
  }
}

function verifyChallengeEnable(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  access: string
) {
  if (index === 0) {
    if (challenges[index].isCompleted) {
      return 'challenge-link-completed';
    } else if (access === 'blocked') {
      return 'challenge-link-disabled';
    } else {
      return 'challenge-link';
    }
  }

  if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return 'challenge-link-completed';
    } else if (access === 'blocked') {
      return 'challenge-link-disabled';
    } else {
      return 'challenge-link';
    }
  } else {
    return 'challenge-link-disabled';
  }
}

function isCompleteChallenge(
  challenges: ChallengeWithCompletedNode[],
  index: number,
  access: string
) {
  if (index === 0) {
    if (challenges[index].isCompleted) {
      return <CheckedCurriculum className='challenge-icon' />;
    } else if (access === 'blocked') {
      return <LockChallenge className='challenge-icon' />;
    } else {
      return <UncheckedCurriculum className='challenge-icon' />;
    }
  } else if (challenges[index - 1].isCompleted) {
    if (challenges[index].isCompleted) {
      return <CheckedCurriculum className='challenge-icon' />;
    } else if (access == 'blocked') {
      return <LockChallenge className='challenge-icon' />;
    } else {
      return <UncheckedCurriculum className='challenge-icon' />;
    }
  } else {
    return <LockChallenge className='challenge-icon' />;
  }
}

Challenges.displayName = 'Challenges';

export default connect(null, mapDispatchToProps)(withTranslation()(Challenges));
