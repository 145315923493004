import React from 'react';

function Terminal(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <mask
        id='mask0_3151_3299'
        style={{ maskType: 'alpha' }}
        width='24'
        height='24'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
      </mask>
      <g mask='url(#mask0_3151_3299)'>
        <path
          fill='#484848'
          d='M4.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308zm0-1.5h15.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8H4v9.692c0 .077.032.148.096.212a.294.294 0 00.212.096zM7.5 16.644L6.456 15.6 9.03 13l-2.6-2.6L7.5 9.356 11.144 13 7.5 16.644zm4.75.106v-1.5h5.5v1.5h-5.5z'
        ></path>
      </g>
    </svg>
  );
}

Terminal.displayName = 'Terminal';

export default Terminal;
