import React, { ReactNode, useState } from 'react';
import { Panel } from '@freecodecamp/react-bootstrap';

import UncheckedCircle from '../../../assets/icons/unchecked-circle';
import CheckedCircle from '../../../assets/icons/checked-circle';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';
import ChevronUp2 from '../../../assets/icons/chevron-up-2';

import ModalUnlockContent from '../../modals/ModaUnlockContent';
import AccordionProgressBar from './components/accordion-progress-bar';

import './accordion.css';

type AccordionProps = {
  readonly isExpanded: boolean;
  readonly blockTitle: string;
  readonly children: ReactNode;
  readonly progressBarInfo: {
    completedCount: number;
    numberChallenges: number;
  };
  readonly progressText?: 'type1' | 'type2' | 'type3';
  readonly isChecked: boolean;
  readonly isLocked?: boolean;
  readonly showUnlockModal?: boolean;
  readonly handleBlockClick: () => void;
};

function Accordion({
  blockTitle,
  children,
  isExpanded,
  progressBarInfo,
  progressText,
  isChecked,
  isLocked = false,
  showUnlockModal = false,
  handleBlockClick
}: AccordionProps): JSX.Element {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const exandeble = !isLocked && isExpanded;

  const handleClick = () => {
    if (showUnlockModal) {
      setIsOpenModal(true);
      return;
    }

    handleBlockClick();
  };

  return (
    <>
      <Panel expanded={exandeble} bsClass='custom-accordion'>
        <Panel.Heading
          onClick={handleClick}
          className={exandeble ? 'extended' : ''}
          style={{ opacity: isLocked ? 0.5 : 1 }}
        >
          <div className='accordion-header-container'>
            <div className='accordion-icon'>
              {isChecked ? (
                <CheckedCircle fill='#5BD69C' />
              ) : (
                <UncheckedCircle />
              )}
            </div>

            <div className='accordion-middle-top-bar'>
              <h4 className='accordion-title'>{blockTitle}</h4>

              {progressBarInfo.completedCount > 0 && (
                <AccordionProgressBar
                  sentence={progressText}
                  progressSize='small'
                  {...progressBarInfo}
                />
              )}
            </div>

            <div className='accordion-icon'>
              {exandeble ? <ChevronUp2 /> : <ChevronDown2 />}
            </div>
          </div>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>{children}</Panel.Body>
        </Panel.Collapse>
      </Panel>

      <ModalUnlockContent
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
      />
    </>
  );
}

export default Accordion;
