import React from 'react';

function LockChallenge(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.1818 9.16675H6.81818C5.81403 9.16675 5 9.99585 5 11.0186V15.6482C5 16.671 5.81403 17.5001 6.81818 17.5001H13.1818C14.186 17.5001 15 16.671 15 15.6482V11.0186C15 9.99585 14.186 9.16675 13.1818 9.16675Z'
        fill='#707070'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.66602 6.47428C6.66602 5.64123 7.01721 4.84229 7.64233 4.25324C8.26745 3.66418 9.11529 3.33325 9.99935 3.33325C10.8834 3.33325 11.7313 3.66418 12.3564 4.25324C12.9815 4.84229 13.3327 5.64123 13.3327 6.47428V9.16659H12.3803V6.47428C12.3803 5.87924 12.1295 5.30858 11.6829 4.88782C11.2364 4.46707 10.6308 4.23069 9.99935 4.23069C9.36788 4.23069 8.76228 4.46707 8.31576 4.88782C7.86925 5.30858 7.6184 5.87924 7.6184 6.47428V9.16659H6.66602V6.47428Z'
        fill='#707070'
      />
    </svg>
  );
}

LockChallenge.displayName = 'LockChallenge';

export default LockChallenge;
