import React from 'react';

function News(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <mask
        id='mask0_3151_3281'
        style={{ maskType: 'alpha' }}
        width='24'
        height='24'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H24V24H0z'></path>
      </mask>
      <g mask='url(#mask0_3151_3281)'>
        <path
          fill='#484848'
          d='M5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h10.48L20.5 8.212v10.48c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zm0-1.5h13.384a.3.3 0 00.221-.087.3.3 0 00.087-.22V9h-4V5H5.308a.3.3 0 00-.221.087.3.3 0 00-.087.22v13.385a.3.3 0 00.087.221.3.3 0 00.22.087zm1.942-2.5h9.5V15h-9.5v1.5zm0-7.5H12V7.5H7.25V9zm0 3.75h9.5v-1.5h-9.5v1.5z'
        ></path>
      </g>
    </svg>
  );
}

News.displayName = 'News';

export default News;
