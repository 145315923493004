import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { SuperBlocks } from '../../../../../config/certification-settings';
import { ChallengeNode, CompletedChallenge } from '../../../redux/prop-types';
import { completedChallengesSelector } from '../../../redux/selectors';
import BackButton from '../../../components/ui/BackButton';
import AccordionProgressBar from '../../../components/ui/Accordion/components/accordion-progress-bar';
import {
  calculateAmountOfCompletedPhases,
  verifyPhasesCount
} from '../../../utils/all-challenge-nodes/calculate-challenges';

interface SuperBlockIntroData {
  phases: {
    nodes: ChallengeNode[];
  };
}

interface SuperBlockIntroProps {
  readonly superBlock: SuperBlocks;
  readonly completedChallengeIds: string[];
}

const mapStateToProps = createSelector(
  completedChallengesSelector,
  (completedChallenges: CompletedChallenge[]) => ({
    completedChallengeIds: completedChallenges.map(({ id }) => id)
  })
);

function SuperBlockIntro({
  superBlock,
  completedChallengeIds
}: SuperBlockIntroProps): JSX.Element {
  const { t } = useTranslation();

  const data: SuperBlockIntroData = useStaticQuery(graphql`
    query SuperBlockIntro {
      themes: allChallengeNode(
        sort: { fields: [challenge___superOrder] }
        filter: { challenge: { challengeOrder: { eq: 0 } } }
      ) {
        nodes {
          challenge {
            superBlock
            block
            time
          }
        }
      }
      challenges: allChallengeNode(
        sort: { fields: [challenge___superOrder] }
        filter: { challenge: { challengeType: { nin: 3 } } }
      ) {
        nodes {
          challenge {
            id
            block
            superBlock
            dashedName
          }
        }
      }
      phases: allChallengeNode(
        sort: { fields: [challenge___superOrder, challenge___phaseOrder] }
      ) {
        nodes {
          challenge {
            id
            superBlock
            phaseOrder
          }
        }
      }
    }
  `);

  const {
    phases: { nodes }
  } = data;

  type SuperBlockIntroObjProps = { title: string; intro: string[] };

  const superBlockIntroObj: SuperBlockIntroObjProps | string | null = t(
    `intro:${superBlock}`
  );

  if (superBlockIntroObj === null || typeof superBlockIntroObj === 'string') {
    return <></>;
  }

  const { title: i18nSuperBlock, intro: superBlockIntroText } =
    superBlockIntroObj;

  const superBlockIntroTextArray: string[] = Array.isArray(superBlockIntroText)
    ? superBlockIntroText
    : [];

  const countPhases = verifyPhasesCount(superBlock, nodes);
  const countPhasesCompleted = calculateAmountOfCompletedPhases(
    superBlock,
    nodes,
    completedChallengeIds
  );

  return (
    <div className='intro-container'>
      <BackButton prevUrl='/learn' />

      <h2 className='heading-super-block'>{i18nSuperBlock}</h2>

      {superBlockIntroTextArray.map(str => (
        <p className='content-intro' key={str}>
          {str}
        </p>
      ))}

      <AccordionProgressBar
        completedCount={countPhasesCompleted}
        numberChallenges={countPhases}
        sentence='type2'
      />
    </div>
  );
}

SuperBlockIntro.displayName = 'SuperBlockIntro';

export default connect(mapStateToProps)(SuperBlockIntro);
