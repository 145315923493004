import type { TFunction } from 'i18next';
import { Panel } from '@freecodecamp/react-bootstrap';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { SuperBlocks } from '../../../../../config/certification-settings';
import { ChallengeNode, CompletedChallenge } from '../../../redux/prop-types';
import { completedChallengesSelector } from '../../../redux/selectors';
import { makeExpandedBlockSelector, toggleBlock } from '../redux';
import { calculateChallengesWithCompleted } from '../../../utils/all-challenge-nodes/calculate-challenges';

import CheckedCircle from '../../../assets/icons/checked-circle';
import UncheckedCircle from '../../../assets/icons/unchecked-circle';
import ChevronUp2 from '../../../assets/icons/chevron-up-2';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';

import Challenges from './challenges';

import '../intro.css';
import './block.css';

const mapStateToProps = (
  state: unknown,
  ownProps: { blockDashedName: string; isUnlocked: boolean }
) => {
  const expandedSelector = makeExpandedBlockSelector(
    ownProps.blockDashedName,
    ownProps.isUnlocked
  );

  return createSelector(
    expandedSelector,
    completedChallengesSelector,
    (isExpanded: boolean, completedChallenges: CompletedChallenge[]) => ({
      isExpanded,
      completedChallengeIds: completedChallenges.map(({ id }) => id)
    })
  )(state as Record<string, unknown>);
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ toggleBlock }, dispatch);

interface BlockProps {
  blockDashedName: string;
  challenges: ChallengeNode[];
  completedChallengeIds: string[];
  isExpanded: boolean;
  phase: string;
  superBlock: SuperBlocks;
  access: string;
  isUnlocked: boolean;
  t: TFunction;
  toggleBlock: typeof toggleBlock;
}

class Block extends Component<BlockProps> {
  constructor(props: BlockProps) {
    super(props);

    this.handleBlockClick = this.handleBlockClick.bind(this);
  }

  handleBlockClick(): void {
    const { blockDashedName, toggleBlock } = this.props;
    toggleBlock(blockDashedName);
  }

  render(): JSX.Element {
    const {
      blockDashedName,
      completedChallengeIds,
      challenges,
      isExpanded,
      phase,
      superBlock,
      access,
      isUnlocked,
      t
    } = this.props;

    const numberChallenges = challenges.length;

    const { challengesWithCompleted, completedCount } =
      calculateChallengesWithCompleted(challenges, completedChallengeIds);

    const blockTitle = t(
      `intro:${superBlock}.phases.${phase}.blocks.${blockDashedName}.title`
    );

    return (
      <ScrollableAnchor id={blockDashedName}>
        <Panel expanded={isExpanded} bsClass='custom-inner-accordion'>
          <Panel.Heading onClick={() => this.handleBlockClick()}>
            <div
              className='accordion-header-container'
              style={{ opacity: isUnlocked ? 1 : 0.5 }}
            >
              <div className='accordion-icon'>
                {numberChallenges === completedCount ? (
                  <CheckedCircle fill='#5BD69C' />
                ) : (
                  <UncheckedCircle />
                )}
              </div>

              <div className='accordion-middle-top-bar'>
                <h4 className='accordion-title'>{blockTitle}</h4>
              </div>

              <div className='accordion-icon'>
                {isExpanded ? <ChevronUp2 /> : <ChevronDown2 />}
              </div>
            </div>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              {/* challenges */}
              <Challenges
                access={access}
                challengesWithCompleted={challengesWithCompleted}
              />
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </ScrollableAnchor>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Block));
